<template>
    <div class="dataB-addGroup">
        <div class="form-group">
            <label>目录名称</label>
            <el-input v-model="form.name"></el-input>
        </div>
        <div class="btn-group">
            <span class="sure-btn" @click="handleSubmit">确定</span>
            <span class="cancel-btn" @click="handleCancel">取消</span>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            form: {
            },
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 新增
        handleSubmit() {
            const data = this.form;
            data.parent_id = this.extr.parent_id;
            if (this.extr.handleType === 1) {
                this.$axios
                    .post('/interfaceApi/datacenter/interface/directory/save', data)
                    .then(res => {
                        if (res) {
                            this.$message.success('添加成功');
                            this.extr.callBackFn();
                            window.hideToast();
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$axios
                    .post('/interfaceApi/datacenter/interface/directory/save', data)
                    .then(res => {
                        if (res) {
                            this.$message.success('修改成功');
                            this.extr.callBackFn();
                            window.hideToast();
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }

        },
        // 取消
        handleCancel() {
            window.hideToast();
        },
    },
    created() {
        const handleType = this.extr.handleType;
        if (handleType === 2) {
            const node = JSON.parse(JSON.stringify(this.extr.node));
            this.form = node;
        }
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.dataB-addGroup
    padding .3rem .4rem
    .form-group
        overflow hidden
        margin-bottom .2rem
        label
            width 1.5rem
            float left
            line-height .36rem
            height .36rem
        .el-input
            width calc(100% - 1.5rem)
            float left
        .el-textarea
            width calc(100% - 1.5rem)
            float left
    .tips
        font-size .13rem
        margin-top .4rem
        .tit
            .icontishi
                color #EB656F
                font-size .13rem
                margin-right .1rem
            .text
                color #333333
        .info
            margin-top .1rem
            color #979EA7
            p
                line-height .24rem
    .btn-group
        position absolute
        left 0
        bottom .2rem
        width 100%
        text-align center
        .sure-btn
            width 1.8rem
            height .4rem
            background rgba(21,119,210,1)
            color #ffffff
            text-align center
            line-height .4rem
            display inline-block
            margin-right .2rem
            cursor pointer
        .cancel-btn
            width 1.8rem
            height .4rem
            border 1px solid rgba(21,119,210,1)
            color rgba(21,119,210,1)
            text-align center
            line-height .4rem
            display inline-block
            box-sizing border-box
            cursor pointer
</style>